import './footer.css'

function Footer(){
    // gen footer with copyright

    return(
        <>
            <footer className="footer">
            <div>
                <p>© 2023 - All Rights Reserved</p>
            </div>
        </footer>
        </>
    );
}

export default Footer;


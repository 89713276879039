// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}


.login-form {
    background-color: #fff;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.login-form h1 {
    color: #007BFF;
}

.login-form-input {
    margin-bottom: 20px;
}

.login-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.login-form input[type="text"],
.login-form input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.login-form input[type="submit"] {
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.login-form input[type="submit"]:hover {
    background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/components/login/login.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;;AAGA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,0CAA0C;IAC1C,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;;AAGA;IACI,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;;IAEI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["\n.login-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n}\n\n\n.login-form {\n    background-color: #fff;\n    border-radius: 8px;\n    padding: 30px;\n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n    text-align: center;\n    width: 300px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n\n.login-form h1 {\n    color: #007BFF;\n}\n\n.login-form-input {\n    margin-bottom: 20px;\n}\n\n.login-form label {\n    display: block;\n    font-weight: bold;\n    margin-bottom: 5px;\n}\n\n.login-form input[type=\"text\"],\n.login-form input[type=\"password\"] {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    font-size: 16px;\n}\n\n.login-form input[type=\"submit\"] {\n    background-color: #007BFF;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    padding: 10px 20px;\n    font-size: 16px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n}\n\n.login-form input[type=\"submit\"]:hover {\n    background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

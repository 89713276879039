// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this CSS to your existing styles or create a new CSS file for NewsDetail */

.detail-news-container {
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60rem;
}

.detail-news-item {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    width: 70%;
    text-align: left;
    max-width: 800px;
    margin: 0 auto; /* Center the news-item horizontally */
}

.detail-news-item h2 {
    font-size: 1.5rem;
    margin: 1rem;
}

.detail-news-item p {
    margin: 1rem;
}

.detail-news-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.detail-share-button,
.detail-back-button {
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    text-decoration: none;
}

.detail-share-button:hover,
.detail-back-button:hover {
    background-color: #0056b3;
}

.detail-news-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/news/newsdetail.css"],"names":[],"mappings":"AAAA,iFAAiF;;AAEjF;IACI,kBAAkB;IAClB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,UAAU;IACV,gBAAgB;IAChB,gBAAgB;IAChB,cAAc,EAAE,sCAAsC;AAC1D;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;;IAEI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,qBAAqB;AACzB;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,cAAc;IACd,YAAY;AAChB","sourcesContent":["/* Add this CSS to your existing styles or create a new CSS file for NewsDetail */\n\n.detail-news-container {\n    text-align: center;\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 60rem;\n}\n\n.detail-news-item {\n    background-color: #f0f0f0;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    padding: 15px;\n    width: 70%;\n    text-align: left;\n    max-width: 800px;\n    margin: 0 auto; /* Center the news-item horizontally */\n}\n\n.detail-news-item h2 {\n    font-size: 1.5rem;\n    margin: 1rem;\n}\n\n.detail-news-item p {\n    margin: 1rem;\n}\n\n.detail-news-actions {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 1rem;\n}\n\n.detail-share-button,\n.detail-back-button {\n    background-color: #007BFF;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    padding: 5px 10px;\n    cursor: pointer;\n    text-decoration: none;\n}\n\n.detail-share-button:hover,\n.detail-back-button:hover {\n    background-color: #0056b3;\n}\n\n.detail-news-image {\n    max-width: 100%;\n    height: auto;\n    display: block;\n    margin: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add these styles to your aboutus.css file */

.aboutus-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-top: 5rem;
    min-height: 70vh;
}

.aboutus-content {
    background-color: #fff;
    border-radius: 8px;
    padding: 40px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 600px; /* Adjust the width to make it wider */
    margin: 20px; /* Increase the margins for more spacing inside the box */
}

.aboutus-content h1 {
    color: #007BFF;
    font-size: 24px;
    margin-bottom: 20px;
}

.aboutus-content p {
    font-size: 16px;
    line-height: 1.6;
}

.aboutus-image {
    width: 200px;
    height: 150px;
    margin: 20px; /* Add margin around the images */
    border: 1px solid #ccc;
    border-radius: 5px;
    object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/components/aboutus/aboutus.css"],"names":[],"mappings":"AAAA,8CAA8C;;AAE9C;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,0CAA0C;IAC1C,kBAAkB;IAClB,YAAY,EAAE,sCAAsC;IACpD,YAAY,EAAE,yDAAyD;AAC3E;;AAEA;IACI,cAAc;IACd,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,YAAY,EAAE,iCAAiC;IAC/C,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":["/* Add these styles to your aboutus.css file */\n\n.aboutus-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: auto;\n    margin-top: 5rem;\n    min-height: 70vh;\n}\n\n.aboutus-content {\n    background-color: #fff;\n    border-radius: 8px;\n    padding: 40px;\n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n    text-align: center;\n    width: 600px; /* Adjust the width to make it wider */\n    margin: 20px; /* Increase the margins for more spacing inside the box */\n}\n\n.aboutus-content h1 {\n    color: #007BFF;\n    font-size: 24px;\n    margin-bottom: 20px;\n}\n\n.aboutus-content p {\n    font-size: 16px;\n    line-height: 1.6;\n}\n\n.aboutus-image {\n    width: 200px;\n    height: 150px;\n    margin: 20px; /* Add margin around the images */\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.footer {
    background-color: #35638f;
    color: white;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 5rem;

}

.footer-big-margin{
    background-color: #35638f;
    color: white;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 15rem;

}

.footer-absolute {
    background-color: #35638f;
    color: white;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 3rem;
    /*    make it go to the bottom of the page*/
    position: absolute;
    bottom: 0;
    width: 100%;
}



`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.css"],"names":[],"mappings":";;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;IACpB,gBAAgB;;AAEpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;IACpB,iBAAiB;;AAErB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;IACpB,gBAAgB;IAChB,2CAA2C;IAC3C,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf","sourcesContent":["\n\n.footer {\n    background-color: #35638f;\n    color: white;\n    text-align: center;\n    padding-top: 1rem;\n    padding-bottom: 1rem;\n    margin-top: 5rem;\n\n}\n\n.footer-big-margin{\n    background-color: #35638f;\n    color: white;\n    text-align: center;\n    padding-top: 1rem;\n    padding-bottom: 1rem;\n    margin-top: 15rem;\n\n}\n\n.footer-absolute {\n    background-color: #35638f;\n    color: white;\n    text-align: center;\n    padding-top: 1rem;\n    padding-bottom: 1rem;\n    margin-top: 3rem;\n    /*    make it go to the bottom of the page*/\n    position: absolute;\n    bottom: 0;\n    width: 100%;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
